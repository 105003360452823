<template>
  <div>
    <router-view/>
    <Chat />
  </div>
</template>
<script>
import Chat from './views/Chat/index.vue';
export default {
  name: 'App',
  components: {
    Chat
  },
  data() {
    return {};
  },
  async created() {
    console.log('1起2');
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="scss">
@import "~@/assets/css/reset.scss";
</style>

