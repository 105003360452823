import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@babel/polyfill';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'animate.css';
// import vue3VideoPlay from './components/vue3-video-play/index'; // 引入组件
// import './components/vue3-video-play/style.css'; // 引入css
router.beforeEach((to, from, next) => {

  if (to.path==='/about'){
    localStorage.setItem('honner',true);
  } else {
    localStorage.clear();
  }
  window.scrollTo({
    top: 0
  });
  next();
});

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual';
}
createApp(App)
  .use(ElementPlus)
  .use(store)
  .use(router)
  // .use(vue3VideoPlay)
  .mount('#app');
