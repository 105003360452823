import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  //首页
  {
    path: '/',
    redirect: '/index'
  },
  //首页
  {
    path: '/index',
    name: 'home',
    component: () => import(/* webpackChunkName: "scene" */ '../views/Scene/index.vue')
  },
  //关于我们
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/NumberPayment/index.vue')
  },
  //常见问题
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "about" */ '../views/Help/index.vue')
  },
  //常见问题
  {
    path: '/navigator',
    name: 'navigator',
    component: () => import(/* webpackChunkName: "about" */ '../views/Navigator/index.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
