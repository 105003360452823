<template>
  <div>
    <div
      class="chat"
      :class="{ chat_2: customerShow }"
      @click="openCustomer"
    ></div>
    <iframe
      class="chat_iframe"
      :src="iframeSrc"
      frameborder="0"
      v-if="customerShow"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'Chat',
  data() {
    return {
      customerShow: false,
      iframeSrc:
        'https://smartxmaweb.gomefinance.com.cn/vHelp?token=BVNjaiBNJ3Yf&showChat=true&layout=true'
    };
  },
  methods: {
    openCustomer() {
      this.customerShow = !this.customerShow;
    }
  }
};
</script>

<style lang="scss" scoped>
.chat {
  position: fixed;
  z-index: 2147483583;
  width: 60px;
  height: 60px;
  background: url("../../assets/img/chat_1.png") no-repeat;
  background-size: 100%;
  right: 40px;
  bottom: 20px;
}
.chat_2 {
  background: url("../../assets/img/chat_2.png") no-repeat;
  background-size: 100%;
}
.chat_iframe {
  box-shadow: rgba(15, 66, 76, 0.25) 0px 0px 24px 0px;
  border-radius: 5px 5px 0px 0px;
  position: fixed;
  height: 460px;
  overflow: hidden;
  z-index: 2147483647;
  border: none;
  bottom: 105px;
  right: 40px;
  width: 722px;
}
</style>